import { EventResolutionInformation } from '@amzn/ring-neighbors-api-orchestrator-gql-schema';
import { useDisclosure } from '@amzn/ring-ui-react-components';
import { useCallback } from 'react';
import { useEventCardUpdate } from '../context';
import { useNeighborsWebClickEventStreamDispatcher } from 'src/components/App/hooks';
import { useIsNpssUser } from 'src/auth';

export interface UseResolveModalOptionsProps
  extends Partial<
    Pick<EventResolutionInformation, 'is_resolved' | 'resolved_message'>
  > {
  onResolve?: (...args: any[]) => Promise<any>;
  onUnresolve?: () => Promise<any>;
}

export const useResolveModalOptions = ({
  is_resolved,
  resolved_message,
  onResolve,
  onUnresolve,
}: UseResolveModalOptionsProps) => {
  const isNpssUser = useIsNpssUser();
  const dispatchClick =
    useNeighborsWebClickEventStreamDispatcher('nhWeb_myPosts');
  const { setIsResolved } = useEventCardUpdate();
  const {
    isOpen: isUnresolvedOpen,
    onOpen: onUnresolvedOpen,
    onClose: onUnresolvedClose,
  } = useDisclosure();
  const {
    isOpen: isResolvedOpen,
    onOpen: onResolvedOpen,
    onClose: onResolvedClose,
  } = useDisclosure();

  const onClick = useCallback(() => {
    if (is_resolved) {
      onUnresolvedOpen();
    } else {
      onResolvedOpen();
    }
  }, [is_resolved, onUnresolvedOpen, onResolvedOpen]);

  const handleResolve = useCallback(() => {
    onResolve?.(
      isNpssUser
        ? {
            variables: {
              resolved_message: resolved_message,
            },
          }
        : {
            variables: {
              message: resolved_message,
            },
          },
    ).then(() => {
      setIsResolved(true);
      onResolvedClose();
    });
    dispatchClick?.('nhWeb_resolvePostCompleted', [], {});
  }, [
    onResolve,
    onResolvedClose,
    resolved_message,
    setIsResolved,
    dispatchClick,
    isNpssUser,
  ]);

  const handleUnresolve = useCallback(() => {
    onUnresolve?.().then(() => {
      setIsResolved(false);
      onUnresolvedClose();
    });
  }, [onUnresolve, onUnresolvedClose, setIsResolved]);

  return {
    onClick,
    resolve: {
      isOpen: isResolvedOpen,
      onClose: onResolvedClose,
      onSubmit: handleResolve,
    },
    unresolve: {
      isOpen: isUnresolvedOpen,
      onClose: onUnresolvedClose,
      onSubmit: handleUnresolve,
    },
  };
};
